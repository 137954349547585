var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('div', {
    staticClass: "main-contents"
  }, [!_vm.$vuetify.breakpoint.mobile ? _c('slide-banner', {
    staticClass: "main-pc",
    attrs: {
      "code": "main-pc"
    }
  }) : _c('slide-banner', {
    staticClass: "main-mo",
    attrs: {
      "code": "main-mo"
    }
  }), _c('v-responsive', {
    staticClass: "overflow-visible",
    attrs: {
      "width": "100%",
      "min-height": "100%"
    }
  }, [_c('v-responsive', {
    staticClass: "main-section main-section--product mx-auto",
    staticStyle: {
      "overflow": "visible"
    },
    attrs: {
      "width": "100%",
      "max-width": "1230"
    }
  }, [_c('main-giftcard', [_c('span', {
    staticClass: "font-weight-bold main-category--title",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_vm._v("모바일 쿠폰")])])], 1)], 1), _c('div', {
    staticClass: "main-section main-section--banner"
  }, [_c('div', {
    staticClass: "section-banner--wrap"
  }, [_c('img', {
    staticClass: "event-pc",
    attrs: {
      "src": "images/plusu/main/main-banner.png",
      "alt": "secion-banner"
    }
  }), _c('img', {
    staticClass: "event-mo",
    attrs: {
      "src": "images/plusu/main/main-banner-mo.png",
      "alt": "secion-banner"
    }
  })])]), _c('div', {
    staticClass: "main-section main-section--board"
  }, [_c('div', {
    staticClass: "section-board-wrap",
    staticStyle: {
      "max-width": "1230px",
      "width": "100%",
      "padding": "0px 15px",
      "margin": "0 auto"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "mt-1 mt-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('main-faq-list'), _c('main-notification-list', {
    staticClass: "mt-7"
  })], 1)], 1)], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }